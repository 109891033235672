import React, { useContext, useEffect, useState } from 'react'
import Thumbnail from '../Programmation/Thumbnail'
import { FilterContext } from './Providers/FilterProvider'
import { EventsContext } from '../Customer/Providers/CustomerEventsProvider'
import EmptyMessage from '../Ui/EmptyMessage'
import { MES_FAVORIS as MES_FAVORIS_FILTER } from './Calendar/Constants'

const Artists = ({ data }) => {
  const { filter } = useContext(FilterContext)
  const { includeUid } = useContext(EventsContext)
  const [artists, setArtists] = useState([])
  useEffect(() => {
    /**
         * Check if artist is liked according to his appearances
         * @param artisteId
         * @returns {boolean}
         */
    const isLikedArtist = (artisteId) => {
      return data?.allPrismicAppearances?.group?.filter(({ edges }) => {
        return edges?.filter(({ node }) => {
          const { id } = node?.data?.artist?.document !== null ? node?.data?.artist?.document : false
          if (id) {
            return artisteId === id && includeUid(node?.prismicId)
          } else {
            return false
          }
        }).length > 0
      }).length > 0
    }

    const artistIsFree = (artistId) => {
      for (const element of data?.allPrismicAppearances?.group || []) {
        for (const { node } of element?.edges || []) {
          if (artistId === node?.data?.artist?.document?.id) {
            return node?.data?.event?.document?.data?.free
          }
        }
      }
      return false
    }

    /**
         * Get list of artists filtered according to which filter is
         * selected
         * For "Mes favoris" filter check if an appearances of the artist is liked
         * @param artists
         * @returns {*}
         */
    const getFilteredArtists = (artists) => {
      return artists?.filter(({ node }) => {
        const isFree = artistIsFree(node?.id)
        const isRueFestive = node.data?.rue_festive_only
        const isMiscActivities = node.data?.activite_diverses
        return (
          (filter === null) || // If no filter is applied
                    (filter === 'Gratuit' && isFree) ||
                    (filter === 'Activités' && (isRueFestive || isMiscActivities)) ||
                    (filter === MES_FAVORIS_FILTER && isLikedArtist(node.id))
        )
      })
    }

    setArtists(getFilteredArtists(data?.allPrismicArtists?.edges))
  }, [filter, data, includeUid])

  return (
        <>
            <div className="pb-24 lg:block w-full max-w-[1920px] md:px-36 px-4 xl:mx-auto">
                <EmptyMessage className="text-center text-xl text-white py-16 px-8" customText="artiste" dynamicMessage={filter} condition={artists && artists.length > 0}>
                    <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:!mt-[2rem]">

                        { artists?.map(({ node }) => {
                          const { title, main_image: MainImage } = node.data
                          return (
                                <Thumbnail
                                    key={node.id}
                                    title={title.text}
                                    image={MainImage}
                                    to={`/artistes/${node.slug}`}
                                />
                          )
                        })}

                    </section>
                </EmptyMessage>
            </div>
        </>
  )
}

export default Artists
