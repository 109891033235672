import React from 'react'
import LikeButton from '../../Ui/LikeButton'
import { getHours } from '../../../../utils/formattedDate'
import { getMinuteDifference } from '../../../../utils/ranges'

const CalendarCard = ({
  stage,
  id,
  title,
  time,
  rangeSize,
  path,
  to,
  sizeOffset
}) => {
  const cardSizeOffset = sizeOffset || 4
  const cardWidth = rangeSize * (getMinuteDifference(time.start, time.end) / 30)
  const cardStart = rangeSize * (getMinuteDifference(time.rangeStart, time.start) / 30)
  const timeRange = `${getHours(time.start, ':')} - ${getHours(time.end, ':')}`
  return (
      <div style={{ height: '78px', width: `calc(${cardWidth}px - ${cardSizeOffset}px)`, left: `calc(${cardStart}px + ${(cardSizeOffset + 1) / 2}px)` }} className={'top-1/2 -translate-y-1/2 absolute bg-white text-purple-wolf p-1 uppercase'}>
        <div className="font-poppins text-xs">{timeRange}</div>
        <div className={`font-dinnext font-black max-h-12 overflow-hidden ${(cardWidth <= 200 && title?.length > 12) ? 'text-xs !leading-none' : (title?.length > 30) ? 'text-md' : 'text-lg'} !leading-6`}>
          {to ? <a href={`/${path}/${to}`}>{title}</a> : title}
        </div>
        <LikeButton uid={id} colorInverted={true} className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} absolute right-2.5 top-2.5 w-5`} />
          {
              stage ? <div className={`font-dinnext ${(cardWidth <= 128) ? 'text-xxs mt-0' : 'text-sm mt-1'} text-red-wolf2023 leading-none`}>{stage}</div> : null
          }
      </div>
  )
}

export default CalendarCard
