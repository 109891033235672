import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

class HoverImage extends React.Component {

    constructor(props) {
        super(props)
        this.title = props.title;
        this.image = getImage(props.image);
        this.state = {hover: false}
    }
    
    setIsHoverTrue = () => {
        this.setState({hover: true});
    };
    setIsHoverFalse = () => {
        this.setState({hover: false});
    };

    render() {
        return (
            <div className="relative text-center z-0"
                onMouseOver={this.setIsHoverTrue}
                onFocus={this.setIsHoverTrue}
                onMouseOut={this.setIsHoverFalse}
                onBlur={this.setIsHoverFalse}>
    
                {/* ARTIST'S NAME */}
                <div className="absolute w-full bottom-0 z-30 mb-0 h-16 px-4 py-auto flex justify-center items-center rounded-b-2xl bg-purple-wolf2024dark">

                    {/* -1px because it is not centered according to Arno. It's probably the font */}
                    <h2 className="font-ohno text-lg text-white leading-none uppercase mt-[-3px]">{this.title}</h2>
                </div>
    
                {/* ARTIST'S IMAGE */}
                <div className="relative overflow-hidden rounded-2xl">
                    <GatsbyImage
                        className={`w-full h-full duration-300 ${this.state.hover ? 'scale-105' : 'scale-100'} ${this.title.indexOf('Original Gros Bonnet') >= 0 ? '-translate-y-16' : ''}`}
                        image={this.image}
                        alt={"Image de l'artiste"}
                    />
                </div>
            </div>
        )
    }
}

export default HoverImage;
