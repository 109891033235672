import React, { useContext, useEffect } from 'react'
import Button from '../Ui/Button'
import { FilterContext } from './Providers/FilterProvider'
import { AuthContext } from '../Customer/Providers/CustomerAuthProvider'
import { NavContext } from '../Customer/Providers/CustomerNavProvider'
import scrollToElement from '../../../utils/scrollToElement'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { MES_FAVORIS } from './Calendar/Constants'

const ProgrammationFilter = () => {
  const location = useLocation()

  const calendarFilter = 'calendrier'
  const allFilter = 'tous'
  const maSelectionFilter = MES_FAVORIS
  const gratuitFilter = 'Gratuit'
  const miscActivities = 'Activités'

  const { filter, setFilter, calendar, setCalendar, bottomFilterRef } = useContext(FilterContext)
  const { setIsPanelOpen } = useContext(NavContext)
  const { user, loading } = useContext(AuthContext)

  // Switch to calendar view if hash of the url contain #calendrier
  // Switch on filter according to url "filtre" param
  useEffect(() => {
    // get filtre url param
    const urlFilter = new URLSearchParams(location.search).get('filtre')

    // Activate filter if not already activated and user not loading
    if (urlFilter && urlFilter !== filter && !loading) {
      toggleFilter(urlFilter)
    }

    // set view to calendar if hash = calendarFilter
    if (location.hash === `#${calendarFilter}`) { setCalendar(true) }
  }, [location, loading, user])

  const toggleFilter = (newFilter) => {
    // Set default hash to allFilter to avoid page refresh on navigate
    const hash = location.hash === `#${calendarFilter}` ? `#${calendarFilter}` : `#${allFilter}`

    if (newFilter === maSelectionFilter && !user && !loading) {
      setIsPanelOpen(true)
    } else if (newFilter === filter) {
      setFilter(null)
      // Remove all params
      navigate(`${hash}`, { replace: true })
    } else {
      setFilter(newFilter)
      // Remove filtre param using newFilter function parameter
      navigate(`?filtre=${encodeURIComponent(newFilter)}${hash}`, { replace: true })
      scrollToElement(bottomFilterRef && bottomFilterRef.current)
    }
  }

  return (
        <div className="text-center max-w-[1500px] mx-auto">
            <section className="mx-4 md:mx-20 lg:mx-40 py-12 lg:pb-12 lg:pt-0 justify-center px-18">
                <div className="w-full md:flex mb-6 text-center">
                    <div className={`${(process.env.GATSBY_IS_BORNE === 'false' || process.env.GATSBY_IS_BORNE === false) ? 'hidden' : ''} md:w-1/6`}></div>
                    <div className="md:w-1/3 w-full px-2 md:my-0 my-2 transform transition-transform hover:scale-105 duration-300">
                        <Button text="Les artistes"
                                className={` w-full h-full !py-8 toggle-filter-big bg-purple-wolf !leading-6 ${!calendar ? 'toggle-filter-toggled' : ''}`}
                                onClick={() => {
                                  scrollToElement(bottomFilterRef && bottomFilterRef.current)
                                  setCalendar(false)
                                  navigate(`${location.search}#${allFilter}`, { replace: true })
                                }}
                        />
                    </div>
                    <div className="md:w-1/3 w-full px-2 md:my-0 my-2 transform transition-transform hover:scale-105 duration-300">
                        <Button text="Par jour"
                                className={`w-full h-full !py-6 toggle-filter-big !leading-6 ${calendar ? 'toggle-filter-toggled' : ''}`}
                                onClick={() => {
                                  scrollToElement(bottomFilterRef && bottomFilterRef.current)
                                  setCalendar(true)
                                  navigate(`${location.search}#${calendarFilter}`, { replace: true })
                                }}
                        />
                    </div>
                    <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} md:w-1/3 w-full px-2 md:my-0 my-2 transform transition-transform hover:scale-105 duration-300`}>
                        <Button className="w-full h-full !py-8 toggle-filter-big !leading-5"
                                text="Consulter l'horaire en PDF"
                                target='_blank'
                                href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZhbetzjCgu4jzvA8_horaire.pdf"/>
                    </div>
                </div>

                <div className="w-full md:flex mb-6 text-center justify-center">
                    <div className="transform transition-transform hover:scale-105 duration-300 md:w-1/4 w-full px-2 md:my-0 my-2">
                        <Button onClick={() => toggleFilter(maSelectionFilter)}
                                textSize='md:text-lg text-xs'
                                className={`!font-poppins !font-black w-full h-full toggle-filter-big  !text-xl !text-yellow-wolf2024 !px-0 !py-8 ${filter === maSelectionFilter ? '!bg-white/20' : '!bg-pink-wolf2024'}`}
                                text={` ${maSelectionFilter} `}/>
                    </div>
                    <div className="md:w-1/4 w-full px-2 md:my-0 my-2 transform transition-transform hover:scale-105 duration-300">
                        <Button onClick={() => toggleFilter(miscActivities)}
                                textSize='md:text-lg text-xs'
                                className={`!font-poppins !font-black w-full h-full toggle-filter-big  !text-xl !text-purple-wolf2024 !px-0 !py-8 ${filter === miscActivities ? '!bg-white/20' : '!bg-green-wolf2024'}`}
                                text={` ${miscActivities} `}/>
                    </div>
                    <div className="md:w-1/4 w-full px-2 md:my-0 my-2 transform transition-transform hover:scale-105 duration-300">
                        <Button onClick={() => toggleFilter(gratuitFilter)}
                                textSize='md:text-lg text-xs'
                                className={`!font-poppins !font-black w-full h-full toggle-filter-big !text-xl !text-purple-wolf2024 !px-0 !py-8 ${filter === gratuitFilter ? '!bg-white/20' : '!bg-yellow-wolf2024'}`}
                                text={` ${gratuitFilter} `}/>
                    </div>
                </div>
            </section>

        </div>
  )
}

export default ProgrammationFilter
