import { StaticImage } from 'gatsby-plugin-image'
import React, { useContext } from 'react'
import { AuthContext } from '../Customer/Providers/CustomerAuthProvider'
import { Loader } from './index'
import { doc, setDoc, getFirestore, deleteDoc } from 'firebase/firestore'
import app from 'gatsby-plugin-firebase-v9.0'
import { NavContext } from '../Customer/Providers/CustomerNavProvider'
import { EventsContext } from '../Customer/Providers/CustomerEventsProvider'

/**
 * @todo Handle error (user not logged in)
 * @param className
 * @param uid
 * @param colorInverted
 * @returns {JSX.Element}
 * @constructor
 */
const LikeButton = ({ className, uid, colorInverted }) => {
  const db = getFirestore(app)
  const { setIsPanelOpen } = useContext(NavContext)
  const { user } = useContext(AuthContext)
  const { loading, includeUid } = useContext(EventsContext)

  const handleLike = async (uid, type) => {
    if (user && user.uid) {
      if (type === 'like') {
        addLike(uid).then()
      } else {
        deleteLike(uid).then()
      }
    } else {
      setIsPanelOpen(true)
    }
  }

  const addLike = async (uid) => {
    await setDoc(doc(db, 'users', user.uid, 'events', uid), { uid })
  }

  const deleteLike = async (uid) => {
    await deleteDoc(doc(db, 'users', user.uid, 'events', uid))
  }

  return includeUid(uid)
    ? (
    <button onClick={() => handleLike(uid, 'dislike')} className={className}>
      <Loader loading={loading}>
        {colorInverted
          ? (
          <StaticImage
            src={'../../../images/wolf/purple-heart-full.png'}
            placeholder="blurred"
            alt="coeur - favori"
          />
            )
          : (
          <StaticImage
            src={'../../../images/wolf/heart-full.png'}
            placeholder="blurred"
            alt="coeur - favori"
          />
            )}
      </Loader>
    </button>
      )
    : (
    <button onClick={() => handleLike(uid, 'like')} className={className}>
      <Loader loading={loading}>
        {colorInverted
          ? (
          <StaticImage
            src={'../../../images/wolf/purple-heart-empty.png'}
            placeholder="blurred"
            alt="coeur - favori"
          />
            )
          : (
          <StaticImage
            src={'../../../images/wolf/heart-empty.png'}
            placeholder="blurred"
            alt="coeur - favori"
          />
            )}
      </Loader>
    </button>
      )
}

export default LikeButton
