import React from 'react'
import HoverImage from '../../Ui/HoverImage'

const Thumbnail = ({ image, hoverImage, title, to }) => {
  return (
    <a href={to}>
        <HoverImage image={image} hoverImage={hoverImage} title={title} />
    </a>
  )
}

export default Thumbnail
