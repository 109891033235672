import React, {useContext} from "react";
import {formattedDate} from "../../../../utils/formattedDate";
import {CalendarTabContext} from "./Provider/CalendarTabProvider";

const CalendarTab = ({tabIndex, date}) => {
  const {calendarIndex,setCalendarIndex} = useContext(CalendarTabContext)
  return (
      <div key={`calendarTab${tabIndex}`} className={`${tabIndex !== calendarIndex ? 'text-white/20' : 'text-white before:border-b-2'} transition-colors shrink-0 cursor-[inherit] text-center min-w-1/4 uppercase`}>
          <button onClick={() => setCalendarIndex(tabIndex)} className={`uppercase font-black p-6 w-full ${tabIndex !== calendarIndex ? 'before:scale-x-0' : 'before:scale-x-100'} cursor-[inherit] before:origin-left before:absolute before:bottom-5 before:-translate-x-1/2 before:px-6 before:left-1/2 before:w-[calc(100%-3rem)] before:h-0.5 before:transition-transform before:bg-white relative`}>
              {formattedDate(date,{ month: 'short',day:'2-digit' ,weekday: 'short' })}
          </button>
      </div>
  )
}

export default CalendarTab