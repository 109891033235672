import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const BannerWithMobileAppDownloads = ({ title }) => {
  return (
    <header className="header lg:px-20 py-20 leading-none hidden md:block relative">
      <h1 className="mb-2">{ title }</h1>
      <div className="w-full flex relative">
        <div className="w-1/2 flex items-center">
          <p className="w-4/12 font-openSans font-semibold text-[14px] text-right text-white">Télécharge l’application mobile pour recevoir les notifications des Spectacles Imprévisibles Loto-Québec</p>
          <a className="w-3/12 flex-shrink-0 pl-4 pr-1"
             href="https://apps.apple.com/ca/app/le-festif/id895648726"
             target="_blank"
             rel="noreferrer">
            <StaticImage className={'max-w-[250px]'}
                          src={'../../../images/stores/appstore-logo.png'}
                          placeholder="blurred"
                          objectFit="contain"
                          alt="Logo App Store"/>
          </a>
          <a className="w-3/12 flex-shrink-0 pr-4 pl-1"
             href="https://play.google.com/store/apps/details?id=ca.lefestif&hl=fr_CA&gl=US"
             target="_blank"
             rel="noreferrer">
            <StaticImage className={'max-w-[250px]'}
                          src={'../../../images/stores/googleplay-logo.png'}
                          placeholder="blurred"
                          objectFit="contain"
                          alt="Logo Google Play Store" />
          </a>
          <div className="w-2/12"></div>
        </div>

        {/* FUNNY IMAGE */}
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="absolute top-[0.4rem] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <StaticImage className={'max-w-[80px]'}
                          src={'../../../images/wolf/ovni.png'}
                          placeholder="blurred"
                          objectFit="contain"
                          alt="Ovni" />
          </div>
        </div>
      </div>
    </header>
  )
}

export default BannerWithMobileAppDownloads
