import React, { createContext, useState } from 'react'

const CalendarTabContext = createContext(null)

const CalendarTabProvider = ({ children }) => {
  const [calendarIndex, setCalendarIndex] = useState(0)
  return (
          <CalendarTabContext.Provider value={{ calendarIndex, setCalendarIndex }}>
              {children}
          </CalendarTabContext.Provider>
  )
}

export default CalendarTabProvider
export { CalendarTabContext }
