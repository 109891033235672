import React from "react";

/**
 * Show component children if condition is met, if not show message
 * @param className
 * @param children
 * @param condition the condition
 * @param dynamicMessage A message dynamic text the message is different if dynamicMessage is null or not
 * @param dynamicElement custom dynamic element name default : filter
 * @param customText custom text to personnalize the base error message default : évenement
 * @returns {*|JSX.Element}
 * @constructor
 */
const EmptyMessage = ({className,children,condition,dynamicMessage,dynamicElement = "filtre",customText = "évenement"}) => {
   return (
       condition ?
           children
        :
        (
            !dynamicMessage || !dynamicElement
                ?
                <div className={`${className ? className : ""}`}>Aucun {customText} n'a été trouvé pour cette date.</div>
                :
                <div className={`${className ? className : ""}`}>Aucun {customText} n'a été trouvé avec le {dynamicElement} <span className="font-bold italic">{dynamicMessage}</span>.</div>
        )
    )
}

export default EmptyMessage